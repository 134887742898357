// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import add_box_line from 'images/add_box_line.svg';

// Styles
import styles from './AddButton.module.css';

const AddButton = ({ disabled, onClick }) => {
	return (
		<button className={styles.addButton} disabled={disabled} onClick={onClick}>
			<img src={add_box_line} alt="plus" />
			<FormattedMessage
				id={'rod.release.create.step.release.label.artists.add_performer'}
			/>
		</button>
	);
};

export default AddButton;
