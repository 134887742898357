// Core
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';

// Styles
import styles from './ArtistForm.module.css';

const ArtistForm = ({
	isModalActive,
	data,
	index,
	setIsModalActive,
	onSave,
}) => {
	const { accountId } = useContext(AuthContext);
	const { getArtist, createArtist, updateArtist } = useContext(RootContext);
	const formFields = [
		'first_name',
		'last_name',
		'ipi_name_number',
		'ipn',
		'isni',
	];

	const [loading, setLoading] = useState(false);
	const [artist, setArtist] = useState({
		name: '',
		first_name: '',
		last_name: '',
		ipi_name_number: '',
		ipn: '',
		isni: '',
	});
	const [disabled, setDisabled] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (isModalActive && data?.artist_id) {
			setLoading(true);
			getArtist(accountId, data.artist_id)
				.then((res) => setArtist(res.data.data))
				.catch()
				.finally(() => setLoading(false));
		} else {
			setArtist({ ...artist, name: data?.name });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeField = (field) => (e) => {
		switch (field) {
			case 'ipi_name_number':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 11
				) {
					artist[field] = e.target.value;
				}
				break;
			case 'ipn':
				if (
					!e.target.value.includes(' ') &&
					!isNaN(+e.target.value) &&
					e.target.value.length <= 8
				) {
					artist[field] = e.target.value;
				}
				break;
			default:
				artist[field] = e.target.value;
				break;
		}

		setArtist({ ...artist });
	};

	const changeMask = (e) => {
		setErrors({});
		artist.isni = e.target.value;
		setArtist({ ...artist });
	};

	const handleSubmit = () => {
		setDisabled(true);
		const submitAction = data?.artist_id
			? updateArtist(accountId, data.artist_id, artist)
			: createArtist(accountId, artist);

		submitAction
			.then((res) => {
				const savedArtist = res.data.data || artist;
				onSave(savedArtist, index);
				setIsModalActive(false);
			})
			.catch((error) => setErrors(error.response.data.errors))
			.finally(() => setDisabled(false));
	};

	return (
		<>
			{loading ? (
				<Loading className={styles.loader} />
			) : (
				<>
					<div className={styles.form}>
						<div className={`${styles.input} ${styles.pseudonym}`}>
							<FormInput
								type="muiInput"
								name={'name'}
								onChange={changeField}
								errors={errors}
								data={artist}
								label={
									<FormattedMessage
										id={'rod.artist_form.performer_pseudonym'}
									/>
								}
								required
							/>
						</div>
						{formFields.map((field) => (
							<div className={styles.input}>
								{field === 'isni' ? (
									<InputMask
										mask={'9999 9999 9999 9999'}
										name={`isni`}
										onChange={changeMask}
										value={artist.isni ? artist.isni : ''}
									>
										{() => (
											<TextFieldInput
												variant="standard"
												type="muiInput"
												name={'isni'}
												error={Object.keys(errors).length > 0 && errors.isni}
												value={artist.isni ? artist.isni : ''}
												label={
													<FormattedMessage
														id={`rod.artist_form.performer_${field}`}
													/>
												}
											/>
										)}
									</InputMask>
								) : (
									<FormInput
										type="muiInput"
										name={field}
										onChange={changeField}
										errors={errors}
										data={artist}
										label={
											<FormattedMessage
												id={`rod.artist_form.performer_${field}`}
											/>
										}
									/>
								)}

								{Object.keys(errors).includes(field) && (
									<span className={styles.helper}>
										{
											<FormattedMessage
												id={`rod.error.${errors.field[0].rule}`}
											/>
										}
									</span>
								)}
							</div>
						))}
					</div>
					<Button
						className={styles.submitButton}
						variant="primary"
						onClick={handleSubmit}
						disabled={!artist.name || disabled}
					>
						<FormattedMessage
							id={
								data?.artist_id
									? 'rod.action.save'
									: 'rod.release.create.step.release.label.artists.create_performer'
							}
						/>
					</Button>
				</>
			)}
		</>
	);
};

export default ArtistForm;
