// Core
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

// Utils
import { getCountryName } from '../utils/getCountryName';

// Styles
import styles from './TopTen.module.css';

const TopTen = ({ tenTopCountries }) => {
	const lang = localStorage.getItem('lang');

	return (
		<div
			className={styles.topTen}
			style={{ height: `${tenTopCountries.length * 28 + 37}px` }}
		>
			<div className={styles.topTenTitle}>
				<FormattedHTMLMessage id={'rod.statistic.top-ten'} />
			</div>
			<ul className={styles.topTenData}>
				{tenTopCountries.map((item) => (
					<li>
						{getCountryName(item, lang)}
						:&nbsp;
						<span
							style={{
								font: 'var(--gilroy-Bold-14-17)',
								paddingTop: '1px',
							}}
						>
							{item.auditions}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default TopTen;
