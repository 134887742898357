// Core
import React, {
	useState,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { showError } from 'validators/showError';
import debounce from 'lodash.debounce';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// Hooks
import { useClickOutside } from 'hooks';

// UI
import { InputWithFloatingLabel } from '../InputWithFloatingLabel';
import Skeleton from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedAnimation';
import AddButton from './AddButton/AddButton';

// Icons
import artist_id from 'images/artist_id.svg';

// Styles
import styles from './ArtistsAutosuggestInput.module.css';

const ArtistsAutosuggestInput = ({
	value: initialValue,
	onChange,
	performers,
	placeholder,
	errors,
	cleanErrorsField,
	openModal,
}) => {
	const [value, setValue] = useState(initialValue);
	const [items, setItems] = useState([]);
	const [text, setText] = useState(initialValue?.name || initialValue);
	const [isLoading, setLoading] = useState(false);
	const [noSuggestions, setNoSuggestions] = useState(false);
	const [isDropdownOpen, setDropdownOpen] = useState(false);

	const { accountId } = useContext(AuthContext);
	const { searchArtists } = useContext(RootContext);

	const dropdownRef = useRef(null);
	// Close dropdown and reset text when clicking outside
	useClickOutside(dropdownRef, () => {
		setDropdownOpen(false);
		// Reset the input to the initial value or empty it if none existed
		if (initialValue) {
			setText(initialValue?.name || '');
		} else {
			setText('');
		}
	});

	useEffect(() => {
		if (initialValue !== value) {
			setValue(initialValue);
			setText(initialValue?.name || initialValue);
		}
	}, [initialValue, value]);

	const searchArtistsData = useCallback(
		async (searchValue) => {
			try {
				if (searchValue.length < 2) {
					setItems([]);
					return;
				}
				setLoading(true);
				const res = await searchArtists(accountId, searchValue);

				const performersIds = performers
					.map((item) => item.artist_id)
					.filter((id) => id !== null);

				let filteredPerformers = res.data.data;
				if (performersIds.length > 0 && res.data.data.length > 0) {
					filteredPerformers = res.data.data.filter(
						(item) => !performersIds.includes(item.artist_id)
					);
				}
				setItems(filteredPerformers);
				setDropdownOpen(true);
				if (!filteredPerformers.length) {
					setNoSuggestions(true);
				}
			} catch (err) {
				console.error('Error:', err);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setItems]
	);

	const change = (item) => () => {
		setItems([]);
		setValue(item);
		setText(item.name);
		onChange(item);
		setDropdownOpen(false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(
		debounce((searchValue) => searchArtistsData(searchValue), 500),
		[searchArtistsData]
	);

	const changeValue = (searchValue) => {
		cleanErrorsField('performers');
		if (searchValue.length > 2) {
			setLoading(true);
		} else if (searchValue.length < 2 && isLoading) {
			setLoading(false);
		}
		setText(searchValue);
		debouncedChangeHandler(searchValue);
	};

	return (
		<div className={styles.component} ref={dropdownRef}>
			<InputWithFloatingLabel
				placeholder={placeholder}
				errors={errors}
				showError={showError('performers')}
				required
				value={text}
				onChange={changeValue}
			/>
			{text && (
				<img
					onClick={() => openModal(text)}
					className={styles.icon}
					src={artist_id}
					alt=""
				/>
			)}
			{isLoading && (
				<div className={styles.skeleton}>
					<Skeleton />
				</div>
			)}
			{isDropdownOpen &&
				!isLoading &&
				text &&
				text.length > 2 &&
				noSuggestions && (
					<div className={styles.dropdown}>
						<ul className={styles.list}>
							<li className={styles.notFoundItem}>
								<FormattedMessage id="rod.suggest.search.not_found" />
							</li>
							<AddButton disabled={false} onClick={() => openModal(text)} />
						</ul>
					</div>
				)}
			{isDropdownOpen && items.length > 0 && (
				<div className={styles.dropdown}>
					<ul className={styles.list}>
						{items.map((item) => (
							<li key={item.id} onClick={change(item)}>
								{item.name}
							</li>
						))}
						<AddButton disabled={false} onClick={() => openModal(text)} />
					</ul>
				</div>
			)}
		</div>
	);
};

export default ArtistsAutosuggestInput;
