// Core
import { useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Utils
import { showError } from 'validators/showError';

// UI
import { FormInput } from 'components/Form/FormInput';
import { InfoButton } from 'components/Buttons';

// Styles
import s from '../Release.module.css';

const MainTable = ({
	changeField,
	errors,
	personal,
	handleOnBlurDateInput,
	handleFocus,
	handleBlur,
	countries,
	getClassnameForInfoBtn,
	releaseTypes,
	isH11Edit,
}) => {
	const { showModal } = useContext(UIContext);

	return (
		<div className={s.table}>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'title'}
					onChange={changeField}
					errors={errors}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.name'}
						/>
					}
				/>
				{Object.keys(errors).includes('title') && (
					<span className={s.Helper}>
						{showError(errors.title[0].rule)(errors.title[0])}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'muiInputDate_v2'}
					readOnly={false}
					name={'created_date'}
					onChange={changeField}
					handleOnBlurDateInput={handleOnBlurDateInput}
					maxDate={true}
					errors={Object.keys(errors).includes('created_date') ? errors : {}}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.create_date'}
						/>
					}
				/>
				{Object.keys(errors).includes('created_date') && (
					<span className={s.Helper}>
						{showError(errors.created_date[0].rule)(errors.created_date[0])}
					</span>
				)}
				{Object.keys(errors).includes('created_date') && (
					<span className={s.Helper}>
						{
							<FormattedMessage
								id={`rod.error.${errors.created_date[0].rule}`}
							/>
						}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'subtitle'}
					onChange={changeField}
					errors={errors}
					data={personal}
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.subtitle'}
						/>
					}
				/>
				{Object.keys(errors).includes('subtitle') && (
					<span className={s.Helper}>
						{showError(errors.subtitle[0].rule)(errors.subtitle[0])}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormInput
					type="muiInput"
					name={'label'}
					onChange={changeField}
					onFocus={handleFocus}
					onBlur={handleBlur}
					errors={errors}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.recording_info.label.p-line/c-line'}
						/>
					}
				/>
				<InfoButton
					className={getClassnameForInfoBtn()}
					onClick={showModal({
						text: (
							<FormattedHTMLMessage
								id={'rod.release.create.help.c-line/p-line'}
							/>
						),
					})}
				/>
				{Object.keys(errors).includes('label') && (
					<span className={s.Helper}>
						{showError(errors.label[0].rule)(errors.label[0])}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormInput
					type={'MuiSelectReleaseType'}
					name={'release_type_id'}
					onChange={changeField}
					errors={errors}
					data={personal}
					className={s.select}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.relise_type'}
						/>
					}
					items={releaseTypes}
					disabled={
						isH11Edit &&
						(personal.release_type_id === 42 ||
							personal.release_type_id === 43 ||
							personal.release_type_id === 70)
					}
				/>
				{Object.keys(errors).includes('release_type_id') && (
					<span className={s.Helper}>
						{showError(errors.release_type_id[0].rule)(
							errors.release_type_id[0]
						)}
					</span>
				)}
			</div>
			<div className={s.input}>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.label.create_country'}
				>
					{(placeholder) => (
						<FormInput
							type="country"
							name="created_country_id"
							onChange={changeField}
							errors={errors}
							data={personal}
							className={s.select}
							placeholder={placeholder}
							items={countries}
							countries={countries}
						/>
					)}
				</FormattedMessage>
			</div>
			<div className={s.inputGenres}>
				<FormInput
					type={'muiAutocompleteMultipleGenres'}
					name={'genres'}
					onChange={changeField}
					errors={errors}
					className={s.select}
					data={personal}
					required
					label={
						<FormattedMessage
							id={'rod.release.create.step.release.label.genre'}
						/>
					}
				/>
				{Object.keys(errors).includes('genres') && (
					<>
						{errors.genres[0].rule !== 'max_size' ? (
							<span className={s.Helper}>
								{showError(errors.genres[0].rule)(errors.genres[0])}
							</span>
						) : (
							<span className={s.Helper}>
								{errors.genres[0] && (
									<FormattedMessage
										id={'rod.error.max_size.genres'}
										values={{ value: errors.genres[0]?.value }}
									/>
								)}
							</span>
						)}
					</>
				)}
			</div>
			{/* <div className={s.input}>
    <FormInput
      type="muiInput"
      name={'performers'}
      onChange={changeField}
      errors={errors}
      data={personal}
      required
      label={
        <FormattedMessage
          id={'rod.release.create.step.release.label.main_executor'}
        />
      }
    />
    {Object.keys(errors).includes('performers') && (
      <span className={s.Helper}>
        {showError(errors.performers[0].rule)(
          errors.performers[0]
        )}
      </span>
    )}
  </div> */}
		</div>
	);
};

export default MainTable;
